<template>
    <v-card class="elevation-0"
            width="600"
            style="padding: 20px; border-radius: 16px; border: 1px solid #eee">
        <v-card-title class="display-1 font-weight-black" style="word-break: break-word;">
            Не удалось совершить действие. Проверьте правильность ссылки
        </v-card-title>
        <v-card-actions>
            <v-btn @click="exit"
                   color="secondary"
                   style="width: 190px; height: 45px; border-radius: 8px;">Вход
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "link-error-modal",
        methods: {
            exit() {
                this.$router.history.replace({name: 'login'})
            }
        }
    }
</script>

<style scoped>

</style>